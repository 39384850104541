import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
// import Chat from "./pages/Chat";
import Chatbots from "./pages/chat/index";
import SignIn from "./pages/SignIn";
import GuidePage from "./pages/Guide";
import PricingPage from "./pages/Pricing";
import FaqPage from "./pages/Faq";
import UserProfile from "./pages/UserProfile";
function App() {
  return (
    <Router>
      <SnackbarProvider>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/chat" element={<Chatbots />} />
          <Route path="/price" element={<PricingPage />} />
          <Route path="/guide" element={<GuidePage />} />
          <Route path="/faq" element={<FaqPage />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
}
export default App;
