import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  ListItemAvatar,
  ListItemText,
  Fab,
  Paper,
  Divider,
  Badge,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../loader/simple-backdrop";
import ConfirmDialog from "../../../dialog/ConfirmDialog";
import ChatbotFileUploadModal from "./chat-bot-file-upload";
import {
  fetchChatbotList,
  fetchChatbotAndFileLimitDetails,
} from "../../../../features/chatbot/chatbotSlice";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  transition: "all 0.2s ease-in-out",
  border: "1px solid",
  borderColor: theme.palette.divider,
  "&:hover": {
    backgroundColor: theme.palette.grey[50],
    transform: "translateX(4px)",
    boxShadow: theme.shadows[1],
  },
}));

const ScrollableContainer = styled(Box)(({ theme }) => ({
  maxHeight: "60vh",
  overflowY: "auto",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: theme.palette.grey[100],
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey[400],
    borderRadius: "4px",
    "&:hover": {
      background: theme.palette.grey[600],
    },
  },
}));

export default function AllFileList({ chatbotId }) {
  const dispatch = useDispatch();
  const { chatbot } = useSelector((state) => state.chatbot);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [pdfDocuments, setPdfDocuments] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState("");
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);

  const handleOpenFile = async ({ fileUrl }) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/user/chatbot/download-file", {
        fileUrl,
      });
      window.open(response.data, "_blank");
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Error occurred", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleDeleteClick = ({ fileId }) => {
    setSelectedFileId(fileId);
    setConfirmDialogOpen(true);
  };

  const handleDeleteFile = async (fileId) => {
    setLoading(true);
    try {
      await axiosInstance.delete("/user/chatbot/delete-file", {
        data: { fileId, chatbotId },
      });
      enqueueSnackbar("ファイルが正常に削除されました。", {
        variant: "success",
      });
      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      setConfirmDialogOpen(false);
      setSelectedFileId("");
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setPdfDocuments(chatbotData.pdfDocuments);
      }
    }
  }, [chatbot, chatbotId]);

  const FileItem = ({ fileId, fileName, uploadedDate, fileUrl }) => (
    <StyledListItem>
      <Stack direction="row" spacing={2} alignItems="center" flex={1}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "primary.main" }}>
            <FileCopyIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              {fileName || "Untitled File"}
            </Typography>
          }
          secondary={
            <Typography variant="caption" color="text.secondary">
              {format(new Date(uploadedDate), "yyyy年MM月dd日 HH:mm")}
            </Typography>
          }
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Tooltip title="ファイルを開く">
          <IconButton size="small" onClick={() => handleOpenFile({ fileUrl })}>
            <FileOpenIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="ファイルを削除">
          <IconButton
            size="small"
            onClick={() => handleDeleteClick({ fileId })}
          >
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledListItem>
  );

  const UploadSection = ({ onUploadClick }) => (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#f5f5f5",
        borderRadius: "16px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px dashed #b4bac5",
        mt: "auto",
      }}
    >
      <Typography
        textAlign="center"
        variant="overline"
        display="block"
        gutterBottom
      >
        さらにファイルをアップロードしますか?
      </Typography>
      <Fab
        onClick={onUploadClick}
        sx={{
          background: "#000000c9",
          color: "white",
          textTransform: "none",
          mt: 1,
          "&:hover": {
            background: "#000000c9",
            color: "white",
          },
        }}
        variant="extended"
        color="primary"
      >
        <CloudUploadIcon sx={{ mr: 1 }} />
        今すぐアップロード
      </Fab>
    </Box>
  );

  return (
    <>
      {loading && <SimpleBackdrop />}
      {openFileUploadModal && (
        <ChatbotFileUploadModal
          chatbotId={chatbotId}
          open={openFileUploadModal}
          handleChangeModal={(state) => setOpenFileUploadModal(state)}
        />
      )}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={() => handleDeleteFile(selectedFileId)}
          title="ファイルを削除"
          content="このファイルを削除してもよろしいですか？この操作は元に戻せません。"
        />
      )}

      <Card elevation={0} sx={{ bgcolor: "transparent" }}>
        <CardContent>
          {/* Header Section */}

          {/* File List Section */}
          <ScrollableContainer>
            {pdfDocuments.length > 0 ? (
              <List disablePadding>
                {pdfDocuments.map((item, index) => (
                  <FileItem
                    key={item._id}
                    fileId={item._id}
                    fileName={item.fileName}
                    uploadedDate={item.createdAt}
                    fileUrl={item.srcUrl}
                  />
                ))}
              </List>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  py: 8,
                  bgcolor: "grey.50",
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1" color="text.secondary">
                  まだファイルがアップロードされていません。
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  「アップロード」ボタンからファイルを追加できます。
                </Typography>
              </Box>
            )}
          </ScrollableContainer>
          <Divider sx={{ mb: 2 }} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <UploadSection onUploadClick={() => setOpenFileUploadModal(true)} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
