import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChatbotSettings from "../../../chat/chatbox/sub/chat-bot-settings";
import ChatbotEdit from "../../chatbox/sub/chat-bot-edit";
import ConfirmDialog from "../../../dialog/ConfirmDialog";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../../../features/chatbot/chatbotSlice";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../loader/simple-backdrop";

export default function CustomListItem({ chatBot, setSelectedChatbot }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event) => {
    event?.stopPropagation(); // Prevent list item click
    setAnchorEl(null);
  };

  const handleRefresh = () => {
    dispatch(fetchChatbotList());
    dispatch(fetchChatbotAndFileLimitDetails());
  };

  const handleClick = (event) => {
    event.stopPropagation(); // Prevent list item click
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    handleClose();
    // handleRefresh();
    setOpenEditModal(true);
  };

  const handleSettings = () => {
    handleClose();
    setOpenSettingsModal(true);
  };

  const handleDelete = () => {
    handleClose();
    setConfirmDialogOpen(true);
  };

  const handleDeleteChatbot = async () => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/user/chatbot/delete/${chatBot._id}`);
      enqueueSnackbar("Chatbot deleted successfully.", { variant: "success" });

      //Refresh chatbot list
      handleRefresh();

      setConfirmDialogOpen(false);
      setSelectedChatbot(null);
    } catch (error) {
      enqueueSnackbar(error.response?.data || error?.response?.error?.message, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <IconButton
        className="hoverIcon"
        onClick={handleClick}
        sx={{ color: "black" }}
      >
        <MoreVertIcon />
      </IconButton>

      {loading && <SimpleBackdrop />}
      {openSettingsModal && (
        <ChatbotSettings
          chatbotId={chatBot._id}
          open={openSettingsModal}
          handleChangeModal={(state) => setOpenSettingsModal(state)}
        />
      )}
      {openEditModal && (
        <ChatbotEdit
          chatbotId={chatBot._id}
          open={openEditModal}
          handleChangeModal={(state) => setOpenEditModal(state)}
        />
      )}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={handleDeleteChatbot}
          title="チャットボットの削除"
          content="このチャットボットとそのすべてのファイルを削除してもよろしいですか? この操作は元に戻せません。"
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            padding: { md: 2, xs: 1 },
            borderRadius: "10px",
          },
        }}
      >
        <MenuItem onClick={handleEdit}>編集</MenuItem>
        <MenuItem onClick={handleSettings}>設定</MenuItem>
        <MenuItem onClick={handleDelete}>削除</MenuItem>
      </Menu>
    </React.Fragment>
  );
}
