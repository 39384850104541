import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SellIcon from "@mui/icons-material/Sell";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import aiBotImg from "../../images/logo_color_tate.png";
import ChatBoxV2 from "./chatbox/ChatBoxV2";
import Fab from "@mui/material/Fab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateChatbotModal from "./chatbox/sub/create-chatbot-modal";
import UserMenuItem from "../menu/UserMenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../features/chatbot/chatbotSlice";
import { formatDate } from "../../constants/index";
import axiosInstance from "../../api/axiosInstance";
import { enqueueSnackbar } from "notistack";
import SimpleBackdrop from "../loader/simple-backdrop";
import useAuth from "../../auth/useAuth";
import ChatbotListItems from "./ChatbotListItems";
import ShowLimitsView from "./chatbox/sub/show-chatbot-limit";
import EmptyChatList from "./EmptyChatList";
import SubscriptionButton from "../button/SubscriptionButton";
import HeaderMenu from "./HeaderMenu";
// Constants for Drawer
const drawerWidth = 240;

// Mixins for opened and closed drawer states
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#ebebeb",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "#ebebeb",
});

// Styled components for DrawerHeader and AppBar
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "none", // Add this line
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function DynamicChatList() {
  const { chatbot, subscriptionDetails } = useSelector(
    (state) => state.chatbot
  );
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const [userDetails, setUserDetails] = React.useState(null);
  const [openChatbotModal, setOpenChatbotModal] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [selectedChatbot, setSelectedChatbot] = React.useState(null);
  const [chatbots, setChatbots] = React.useState([]);
  const [subscription, setSubscription] = React.useState(null);

  // Handler to manage subscription
  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/payment/create-customer-portal-session"
      );

      window.location.href = `${response.data.url}`;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Something went wrong", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  // Handlers to open and close the drawer
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Logout handler
  const handleLogout = () => {
    if (logout()) navigate("/signin");
  };

  // Sidebar "Create Bot" UI
  const sideBarCreateBotUi = (
    <Box
      sx={{
        position: "absolute",
        bottom: 20,
        left: 5,
        right: 5,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography
        textAlign={"center"}
        variant="overline"
        display="block"
        gutterBottom
      >
        新しいチャットボットを作成しますか？
      </Typography>
      <Fab
        onClick={(event) => {
          event.stopPropagation(); // Prevent drawer from closing
          setOpenChatbotModal(true);
        }}
        sx={{
          width: "100%",
          background: "#000000c9",
          color: "white",
          textTransform: "none",
          "&:hover": {
            background: "#000000c9",
            color: "white",
          },
        }}
        variant="extended"
        size="small"
        color="primary"
      >
        <AddCircleIcon sx={{ mr: 1 }} />
        {open && "チャットボット作成"}
      </Fab>
    </Box>
  );

  // Subscription Sidebar UI
  const subscriptionSidebarUI = (
    <Box
      sx={{
        p: 2,
        position: "absolute",
        bottom: 100,
        left: 5,
        right: 5,
        marginLeft: "auto",
        marginRight: "auto",
        border: "1px dashed #b4bac5",
        borderRadius: "12px",
        m: { xs: 0, md: 1 },
      }}
    >
      {subscriptionDetails.subscriptionDetail.subscription.plan.planId ===
        "FREE_PLAN_01" && (
        <Box sx={{ mb: 2 }}>
          <SubscriptionButton />
        </Box>
      )}

      <UserMenuItem openDrawer={open} />
      {subscription && open && (
        <div>
          <Typography
            sx={{ mt: 1, fontSize: "12px" }}
            fontWeight={600}
            variant="subtitle1"
          >
            {subscription.title}
          </Typography>
          {subscription.status === "canceled" && (
            <Typography
              sx={{ fontSize: "12px", color: "red" }}
              fontWeight={500}
              variant="subtitle1"
            >
              subscription is canceled
            </Typography>
          )}
          {new Date(subscription?.expireDate) < new Date() ? (
            <Typography
              sx={{ fontSize: "12px", color: "red" }}
              fontWeight={500}
              variant="subtitle1"
            >
              subscription is expired <br />
              <span style={{ color: "#075099" }}>
                please renew subscription{" "}
                <IconButton
                  onClick={handleManageSubscription}
                  sx={{ color: "#075099" }}
                  size="small"
                >
                  <SellIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </span>
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: "12px" }}
              fontWeight={600}
              variant="subtitle1"
            >
              プラン更新日 {formatDate(subscription?.expireDate)}
            </Typography>
          )}
          <ShowLimitsView />
        </div>
      )}
    </Box>
  );

  // Effect to handle subscription details
  React.useEffect(() => {
    if (subscriptionDetails.subscriptionDetail) {
      const { expireDate, status } =
        subscriptionDetails.subscriptionDetail.subscription.user.subscription;
      const { title } =
        subscriptionDetails.subscriptionDetail.subscription.plan;

      setSubscription({ expireDate, status, title });

      //Set User details
      const { user } = subscriptionDetails?.subscriptionDetail?.subscription;
      setUserDetails(user);
    }
  }, [subscriptionDetails]);

  // Effect to handle chatbot list and automatic selection
  React.useEffect(() => {
    if (chatbot.chatbots) {
      setChatbots(chatbot.chatbots);

      if (chatbot.chatbots.length === 0) {
        setSelectedChatbot(null);
      }
      // If we have a selected chatbot, update its data with the latest from the list
      else if (selectedChatbot) {
        const updatedChatbot = chatbot.chatbots.find(
          (bot) => bot._id === selectedChatbot._id
        );
        if (updatedChatbot) {
          setSelectedChatbot(updatedChatbot);
        }
      }
      // If no chatbot is selected and we have chatbots, select the first one
      else if (chatbot.chatbots.length > 0 && !selectedChatbot) {
        setSelectedChatbot(chatbot.chatbots[0]);
      }
    }
  }, [chatbot.chatbots, selectedChatbot]); // Remove selectedChatbot from dependencies

  // Fetch chatbots and subscription details on component mount
  React.useEffect(() => {
    dispatch(fetchChatbotList());
    dispatch(fetchChatbotAndFileLimitDetails());
  }, [dispatch]);

  return (
    <Box sx={{ display: "flex" }}>
      {loading && <SimpleBackdrop />}
      {openChatbotModal && (
        <CreateChatbotModal
          open={openChatbotModal}
          handleChangeModal={(state) => setOpenChatbotModal(state)}
        />
      )}
      <CssBaseline />
      <AppBarStyled position="fixed" open={open}>
        <Toolbar
          sx={{
            bgcolor: "#ebebeb",
            boxShadow: "none !important",
            borderBottom: "1px solid #1f1f1f33",
          }}
          maxWidth="xl"
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{ color: "black" }}
            variant="h6"
            noWrap
            component="div"
          >
            Ncc チャットボット
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <HeaderMenu
              userDetails={userDetails}
              onLogout={handleLogout}
            />
          </Box>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ChatbotListItems
          open={open}
          chatbots={chatbots}
          selectedChatbot={selectedChatbot}
          setSelectedChatbot={(chatbot) => setSelectedChatbot(chatbot)}
        />
        {subscriptionSidebarUI}
        {sideBarCreateBotUi}
        <Box sx={{ position: "absolute", mb: 4, mt: 1, ml: 10 }}>
          <img src={aiBotImg} alt="ncc bot" style={{ maxWidth: "50px" }} />
        </Box>
      </DrawerStyled>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* If a chatbot is selected, show ChatBoxV2 */}
        {selectedChatbot && (
          <ChatBoxV2
            chatbot={selectedChatbot}
            initialText="SMART AI が会話に参加しました。"
            type="CHAT"
          />
        )}
        {/* If no chatbots exist, show EmptyChatList */}
        {chatbot?.chatbots?.length === 0 && (
          <EmptyChatList
            open={openChatbotModal}
            handleOpenBotCreateModal={(state) => setOpenChatbotModal(state)}
          />
        )}
      </Box>
    </Box>
  );
}
