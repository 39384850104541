import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Stack,
  Avatar,
  Button,
  Grid,
} from "@mui/material";
import {
  Edit as EditIcon,
  Settings as SettingsIcon,
  Language as LanguageIcon,
  Message as MessageIcon,
  ArrowForward as ArrowForwardIcon,
  RemoveRedEye as PreviewIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { TbMessageChatbot } from "react-icons/tb";
import ChatbotEdit from "./chat-bot-edit";
import ChatbotSettings from "./chat-bot-settings";
import ChatbotPreviewModal from "../preview/ChatbotPreviewModal";
import { useSelector } from "react-redux";
import { RiChatHistoryFill } from "react-icons/ri";
import nccBotLogo from "../../../../images/logo_color_tate.png";
const PreviewCard = motion(Card);
const ActionButton = motion(Button);

const ChatbotManage = ({ chatbotId, showChatHistory, handleChatHistory }) => {
  const [openSettings, setOpenSettings] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const { chatbot, subscriptionDetails } = useSelector(
    (state) => state.chatbot
  );

  const currentChatbot = chatbot?.chatbots?.find(
    (bot) => bot._id === chatbotId
  );

  const handleOpenSettings = () => setOpenSettings(true);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleOpenPreview = () => setOpenPreview(true);
  const handleClosePreview = () => setOpenPreview(false);

  const StatCard = ({ icon, title, value, subtitle }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          transition: "all 0.2s ease",
          "&:hover": {
            boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          },
        }}
      >
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                p: 1.5,
                bgcolor: "primary.main",
                borderRadius: 2,
                color: "white",
              }}
            >
              {icon}
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "1.1rem", fontWeight: 600 }}
              >
                {value} {title}
                <IconButton onClick={handleOpenSettings}>
                  <SettingsIcon />
                </IconButton>
              </Typography>
              {subtitle && (
                <Typography color="text.secondary" variant="caption">
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </motion.div>
  );

  const QuickActionButton = ({ icon, text, onClick }) => (
    <ActionButton
      variant="outlined"
      startIcon={icon}
      onClick={onClick}
      fullWidth
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
      sx={{
        color: "#000000",
        borderColor: "#000000",
        textTransform: "none",
        fontSize: "0.875rem",
        fontWeight: 500,
        justifyContent: "flex-start",
        py: 1.5,
        "&:hover": {
          borderColor: "#000000",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        "& .MuiButton-startIcon": {
          color: "#000000",
        },
      }}
    >
      {text}
    </ActionButton>
  );

  return (
    <Box sx={{ py: 2 }}>
      {/* Header Section */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Card
          variant="outlined"
          sx={{
            mb: 3,
            transition: "all 0.2s ease",
            "&:hover": {
              boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
            },
          }}
        >
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  sx={{
                    bgcolor: "primary.main",
                    width: 48,
                    height: 48,
                    transition: "transform 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  {currentChatbot?.logo?.imgSrc ? (
                    <img
                      src={currentChatbot.logo.imgSrc}
                      alt="Chatbot logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <TbMessageChatbot size={28} />
                  )}
                </Avatar>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {currentChatbot?.name || "Chatbot"}{" "}
                    <IconButton
                      onClick={handleOpenEdit}
                      sx={{
                        transition: "transform 0.2s ease",
                        "&:hover": {
                          transform: "rotate(15deg)",
                        },
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  <Typography
                    sx={{ fontSize: "0.9rem" }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {currentChatbot?.description || "No description"}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </motion.div>

      {/* Stats Grid */}
      <Stack spacing={2}>
        <StatCard
          icon={<LanguageIcon />}
          title="許可されたドメイン"
          value={currentChatbot?.allowedDomains?.length || 0}
          subtitle={`最大${
            subscriptionDetails?.subscriptionDetail?.subscription?.plan
              ?.maxAllowedDomain || 0
          }個まで設定可能`}
        />
      </Stack>

      {/* Quick Actions */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
          クイックアクション
        </Typography>
        <Stack spacing={2}>
          <QuickActionButton
            icon={<EditIcon />}
            text="チャットボットを編集"
            onClick={handleOpenEdit}
          />
          <QuickActionButton
            icon={<SettingsIcon />}
            text="設定を変更"
            onClick={handleOpenSettings}
          />
          <QuickActionButton
            icon={
              !showChatHistory ? <RiChatHistoryFill /> : <TbMessageChatbot />
            }
            text={
              !showChatHistory ? "チャット履歴を開く" : "チャットボットを開く"
            }
            onClick={() => handleChatHistory(!showChatHistory)}
          />
        </Stack>
      </Box>

      {/* Preview Section */}
      <Box sx={{ mt: 2, display: { xs: "none", md: "block" } }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          whileHover={{
            scale: 1.02,
            boxShadow: "0 8px 30px rgba(33, 150, 243, 0.15)",
          }}
          style={{
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
            borderRadius: "24px",
            backgroundColor: "#ffffff",
          }}
          onClick={handleOpenPreview}
        >
          {/* Gradient Background */}
          <motion.div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(135deg, rgba(33, 150, 243, 0.03) 0%, rgba(33, 203, 243, 0.08) 100%)",
              zIndex: 0,
            }}
            whileHover={{
              background:
                "linear-gradient(135deg, rgba(33, 150, 243, 0.06) 0%, rgba(33, 203, 243, 0.12) 100%)",
            }}
          />

          <CardContent sx={{ position: "relative", p: { xs: 2, md: 3 } }}>
            <Grid container spacing={3} alignItems="center">
              {/* Left Side - Robot Animation */}
              <Grid
                item
                xs={12}
                md={4}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                <motion.div
                  initial={{ scale: 0.95, opacity: 0.9 }}
                  animate={{
                    scale: [0.95, 1, 0.95],
                    opacity: [0.9, 1, 0.9],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                  style={{
                    filter: "drop-shadow(0 4px 20px rgba(33, 150, 243, 0.2))",
                    maxWidth: "140px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={nccBotLogo}
                    alt="AI Assistant"
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                </motion.div>
              </Grid>

              {/* Right Side - Content */}
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        background: "linear-gradient(45deg, #2196F3, #21CBF3)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        mb: 1.5,
                        fontSize: "1rem",
                      }}
                    >
                      プレビューを表示
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "text.secondary",
                        maxWidth: "400px",
                        mx: { xs: "auto", md: 0 },
                        fontSize: "0.875rem",
                      }}
                    >
                      実際の表示をご確認いただけます
                    </Typography>
                  </motion.div>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                  >
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          px: 3,
                          py: 1,
                          borderRadius: 2,
                          textTransform: "none",
                          background:
                            "linear-gradient(45deg, #2196F3, #21CBF3)",
                          boxShadow: "0 4px 15px rgba(33, 150, 243, 0.3)",
                          "&:hover": {
                            boxShadow: "0 6px 20px rgba(33, 150, 243, 0.4)",
                          },
                        }}
                        endIcon={
                          <motion.div
                            animate={{
                              x: [0, 5, 0],
                            }}
                            transition={{
                              duration: 1.5,
                              repeat: Infinity,
                              ease: "easeInOut",
                            }}
                          >
                            <ArrowForwardIcon />
                          </motion.div>
                        }
                      >
                        プレビュー
                      </Button>
                    </motion.div>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Decorative Elements */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "40px",
                pointerEvents: "none",
                opacity: 0.4,
              }}
            >
              {[...Array(3)].map((_, i) => (
                <motion.div
                  key={i}
                  style={{
                    position: "absolute",
                    borderRadius: "50%",
                    background: "linear-gradient(45deg, #2196F3, #21CBF3)",
                    width: "8px",
                    height: "8px",
                    top: `${20 + i * 15}%`,
                    right: "5%",
                  }}
                  animate={{
                    y: [-10, 10, -10],
                    opacity: [0.3, 0.6, 0.3],
                  }}
                  transition={{
                    duration: 2,
                    delay: i * 0.3,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
              ))}
            </Box>
          </CardContent>
        </motion.div>
      </Box>

      {/* Modals */}
      {openSettings && (
        <ChatbotSettings
          open={openSettings}
          handleChangeModal={(state) => setOpenSettings(state)}
          chatbotId={chatbotId}
        />
      )}
      {openEdit && (
        <ChatbotEdit
          open={openEdit}
          handleChangeModal={(state) => setOpenEdit(state)}
          chatbotId={chatbotId}
        />
      )}
      {openPreview && (
        <ChatbotPreviewModal
          open={openPreview}
          onClose={handleClosePreview}
          chatbot={currentChatbot}
        />
      )}
    </Box>
  );
};

export default ChatbotManage;
