import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import {
  Close as CloseIcon,
  Laptop,
  PhoneIphone,
  ArrowBack,
  ArrowForward,
  Refresh,
  MoreVert,
  LockOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ChatbotPreview from "./ChatbotPreview";
import { useChatbotPreviewTourGuide } from "../../../guide-tour/chatbotPreview";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "90vw",
    width: "1200px",
    backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#f5f5f5",
    borderRadius: theme.spacing(2),
  },
}));

const HeaderArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DeviceButton = styled(IconButton)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : "transparent",
  color: active
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  border: `1px solid ${
    active ? theme.palette.primary.main : theme.palette.divider
  }`,
  margin: theme.spacing(0, 0.5),
  "&:hover": {
    backgroundColor: active
      ? theme.palette.primary.dark
      : theme.palette.action.hover,
  },
}));

const ChromeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: theme.spacing(1),
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const IPhoneWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#000",
  borderRadius: "45px",
  padding: "12px",
  width: "375px",
  position: "relative",
  boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
  margin: "0 auto",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "150px",
    height: "24px",
    backgroundColor: "#000",
    borderRadius: "0 0 20px 20px",
    zIndex: 2,
  },
}));

const IPhoneScreen = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "35px",
  overflow: "hidden",
  position: "relative",
  height: "720px",
  // Add subtle inner shadow
  boxShadow: "inset 0 0 10px rgba(0,0,0,0.1)",
}));

const SafeAreaInsets = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  height: "44px", // iOS safe area top inset
  background: "linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)",
  zIndex: 1,
  pointerEvents: "none",
});

const BrowserBar = styled(Box)(({ theme }) => ({
  backgroundColor: "#f8f9fa",
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  borderBottom: "1px solid #dfe1e5",
}));

const URLBar = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#fff",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontSize: "14px",
  color: "#666",
  border: "1px solid #dfe1e5",
}));

const ChatbotPreviewModal = ({ open, onClose, chatbot }) => {
  const [deviceType, setDeviceType] = React.useState("desktop");
  const { handleDeviceTypeChange } = useChatbotPreviewTourGuide({
    isOpen: open,
    deviceType,
    onDeviceTypeChange: setDeviceType,
  });

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <HeaderArea>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6" fontWeight={600}>
            プレビューチャットボット
          </Typography>
          <Stack direction="row" spacing={1} sx={{ ml: 4 }}>
            <DeviceButton
              id="tour-desktop-preview"
              size="small"
              active={deviceType === "desktop"}
              onClick={() => handleDeviceTypeChange("desktop")}
            >
              <Laptop />
            </DeviceButton>
            <DeviceButton
              id="tour-mobile-preview"
              size="small"
              active={deviceType === "mobile"}
              onClick={() => handleDeviceTypeChange("mobile")}
            >
              <PhoneIphone />
            </DeviceButton>
          </Stack>
        </Stack>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </HeaderArea>

      <DialogContent sx={{ p: 3, bgcolor: "background.default" }}>
        {deviceType === "desktop" ? (
          <ChromeWrapper>
            <Box
              sx={{
                display: "flex",
                backgroundColor: "#f1f3f4",
                borderBottom: "1px solid #dfe1e5",
                px: 1.5,
                py: 1,
                gap: 1,
              }}
            >
              <Stack direction="row" spacing={0.5}>
                <IconButton size="small" sx={{ color: "#5f6368" }}>
                  <ArrowBack />
                </IconButton>
                <IconButton size="small" sx={{ color: "#5f6368" }}>
                  <ArrowForward />
                </IconButton>
                <IconButton size="small" sx={{ color: "#5f6368" }}>
                  <Refresh />
                </IconButton>
              </Stack>
              <URLBar>
                <LockOutlined sx={{ fontSize: 16, color: "#5f6368" }} />
                {chatbot?.allowedDomains?.[0] || "example.com"}
              </URLBar>
              <IconButton size="small" sx={{ color: "#5f6368" }}>
                <MoreVert />
              </IconButton>
            </Box>
            <Box sx={{ height: "75vh" }}>
              <ChatbotPreview chatbot={chatbot} deviceType="desktop" />
            </Box>
          </ChromeWrapper>
        ) : (
          <IPhoneWrapper>
            <IPhoneScreen>
              <SafeAreaInsets />
              <BrowserBar>
                <IconButton size="small">
                  <ArrowBack sx={{ fontSize: 18 }} />
                </IconButton>
                <URLBar>
                  <LockOutlined sx={{ fontSize: 14 }} />
                  <Typography variant="body2" noWrap>
                    {chatbot?.allowedDomains?.[0] || "example.com"}
                  </Typography>
                </URLBar>
                <IconButton size="small">
                  <MoreVert sx={{ fontSize: 18 }} />
                </IconButton>
              </BrowserBar>
              <Box sx={{ height: "calc(100% - 56px)" }}>
                <ChatbotPreview chatbot={chatbot} deviceType="mobile" />
              </Box>
            </IPhoneScreen>
          </IPhoneWrapper>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default ChatbotPreviewModal;
