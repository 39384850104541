import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  List,
  ListItem,
  Avatar,
  Stack,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import TabContext from "@mui/lab/TabContext";
import CircularProgress from "@mui/material/CircularProgress";
import { TbMessageChatbot } from "react-icons/tb";
import { RefreshRounded } from "@mui/icons-material";
import axiosInstance from "../../../../api/axiosInstance";
import SubscriptionButton from "../../../button/SubscriptionButton";
import { enqueueSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ScrollWrapper = styled(Box)`
  height: 70vh;
  overflow-y: auto;
  padding-right: 8px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ChatFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const MessageBubble = styled(Box)(({ theme, sender }) => ({
  maxWidth: "75%",
  padding: theme.spacing(1.5),
  borderRadius: sender === "bot" ? "18px 18px 18px 4px" : "18px 18px 4px 18px",
  backgroundColor:
    sender === "bot" ? theme.palette.grey[100] : theme.palette.primary.main,
  color:
    sender === "bot"
      ? theme.palette.text.primary
      : theme.palette.primary.contrastText,
  boxShadow: theme.shadows[1],
  marginLeft: sender === "bot" ? 0 : "auto",
  position: "relative",
  "&:before": {
    content: '""',
    position: "absolute",
    bottom: 0,
    [sender === "bot" ? "left" : "right"]: "-8px",
    width: "20px",
    height: "20px",
    background: `radial-gradient(circle at ${
      sender === "bot" ? "left" : "right"
    } bottom, transparent 69%, ${
      sender === "bot" ? theme.palette.grey[100] : theme.palette.primary.main
    } 70%)`,
  },
}));

const TimeStamp = styled(Typography)(({ theme, sender }) => ({
  fontSize: "0.65rem",
  color: sender === "bot" ? theme.palette.text.secondary : "#ffffff8f",
  marginTop: theme.spacing(1),
  textAlign: "end",
}));

const MessageItem = ({ message, sender }) => {
  const theme = useTheme();

  return (
    <ListItem sx={{ px: 0 }}>
      <Stack
        direction={sender === "bot" ? "row" : "row-reverse"}
        spacing={1.5}
        width="100%"
        alignItems="flex-end"
      >
        <Avatar
          sx={{
            bgcolor:
              sender === "bot"
                ? theme.palette.grey[400]
                : theme.palette.primary.dark,
            width: 36,
            height: 36,
          }}
        >
          {sender === "bot" ? <TbMessageChatbot /> : <PersonIcon />}
        </Avatar>

        <MessageBubble sender={sender}>
          <Typography
            variant="body2"
            sx={{ wordBreak: "break-word", fontWeight: 500 }}
          >
            {message.content}
          </Typography>
          <TimeStamp sender={sender}>
            {new Date(message.createdAt).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            <br />
            {new Date(message.createdAt).toLocaleDateString()}
          </TimeStamp>
        </MessageBubble>
      </Stack>
    </ListItem>
  );
};

const ChatView = ({ data, onRefresh, isRefreshing }) => (
  <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
    <ScrollWrapper>
      <List sx={{ p: 2 }}>
        {data.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              まだメッセージがありません
            </Typography>
          </Box>
        ) : (
          data.map((message) => (
            <MessageItem
              key={message._id}
              message={message}
              sender={message.sender}
            />
          ))
        )}
      </List>
    </ScrollWrapper>
    <ChatFooter>
      <Typography
        sx={{ fontSize: "0.8rem", fontWeight: 600 }}
        variant="body2"
        color="textSecondary"
      >
        履歴メッセージ数: {data?.length}
      </Typography>
      <Tooltip title="Refresh messages">
        <IconButton
          onClick={onRefresh}
          disabled={isRefreshing}
          size="small"
          color="primary"
        >
          <RefreshRounded
            sx={{
              animation: isRefreshing ? "spin 1s linear infinite" : "none",
              "@keyframes spin": {
                "0%": { transform: "rotate(0deg)" },
                "100%": { transform: "rotate(360deg)" },
              },
            }}
          />
        </IconButton>
      </Tooltip>
    </ChatFooter>
  </Box>
);

const TabWrapper = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    {children}
  </Box>
);

const transformMessages = (data, type) => {
  return data?.flatMap((item) => [
    {
      _id: `${item._id}-query`,
      content: type === "admin" ? item.query : item.question,
      createdAt: item.createdAt,
      sender: "user",
    },
    ...(item.response
      ? [
          {
            _id: `${item._id}-response`,
            content: item.response,
            createdAt: item.createdAt,
            sender: "bot",
          },
        ]
      : []),
  ]);
};

const AdminQueriesTab = ({ chatbotId }) => {
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/user/chatbot/get-admin-queries/${chatbotId}`
      );
      if (response.data)
        setMessages(transformMessages(response?.data, "admin"));
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Failed to fetch data", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [chatbotId]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchData();
  };

  return (
    <TabWrapper>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <ChatView
          data={messages}
          onRefresh={handleRefresh}
          isRefreshing={isRefreshing}
        />
      )}
    </TabWrapper>
  );
};

const UserQuestionsTab = ({ chatbotId }) => {
  const [messages, setMessages] = React.useState([]);
  const { subscriptionDetails } = useSelector((state) => state.chatbot);
  const [loading, setLoading] = React.useState(true);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/user/chatbot/get-user-questions/${chatbotId}`
      );
      setMessages(transformMessages(response.data, "user"));
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Failed to fetch data", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [chatbotId]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchData();
  };

  const planId =
    subscriptionDetails?.subscriptionDetail?.subscription?.plan?.planId;
  return (
    <TabWrapper>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {planId === "FREE_PLAN_01" || planId === "BASIC_PLAN_01" ? (
            <Box sx={{ mt: 4, maxWidth: 250, mx: "auto", textAlign: "center" }}>
              <SubscriptionButton />
              <Typography
                fontSize={"0.75rem"}
                fontWeight={400}
                sx={{ mt: 2 }}
                variant="body2"
              >
                {" "}
                プレミアムプランを購入し、Webサイトへチャットボットを設置しよう
              </Typography>
            </Box>
          ) : (
            <ChatView
              data={messages}
              onRefresh={handleRefresh}
              isRefreshing={isRefreshing}
            />
          )}
        </Box>
      )}
    </TabWrapper>
  );
};

const ChatbotInteractionTabs = ({ chatbotId, handleChatHistory }) => {
  const [value, setValue] = React.useState("1");

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "#f5f5f5",
        borderRadius: 4,
        height: "100%",
        border: { xs: "1px solid #00000021", md: "none" },
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            bgcolor: "#ebebeb",
            borderRadius: "12px 12px 0 0",
          }}
        >
          <Tabs
            value={value}
            onChange={(e, newVal) => setValue(newVal)}
            aria-label="Chatbot interaction tabs"
          >
            <Tooltip title="チャットボットを開く">
              <IconButton
                onClick={() => handleChatHistory(false)}
                sx={{ ml: 1, mr: 1 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Tab
              label="履歴（管理画面)"
              value="1"
              sx={{ textTransform: "none", fontWeight: 700, color: "black" }}
            />
            <Tab
              label="履歴（利用者)"
              value="2"
              sx={{ textTransform: "none", fontWeight: 700, color: "black" }}
            />
          </Tabs>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <AdminQueriesTab chatbotId={chatbotId} />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <UserQuestionsTab chatbotId={chatbotId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ChatbotInteractionTabs;
