import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Paper,
  Stack,
  Chip,
  Container,
  Button,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import {
  Email as EmailIcon,
  CalendarToday as CalendarIcon,
  AccountBalance as WalletIcon,
  CloudUpload as UploadIcon,
  CloudDownload as DownloadIcon,
  CheckCircle as StatusIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { formatDate } from "../../constants/index";
import { fetchSubscriptionDetails } from "../../features/chatbot/chatbotSlice";

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  variant: "outlined",
  border: `1px solid ${theme.palette.divider}`,
}));

const StatBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.paper
      : theme.palette.grey[50],
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.primary.main : "black",
  borderColor:
    theme.palette.mode === "dark" ? theme.palette.primary.main : "black",
  "&:hover": {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.primary.main : "black",
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.primary.main, 0.1)
        : "rgba(0,0,0,0.04)",
  },
}));

export default function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriptionDetails } = useSelector((state) => state.chatbot);
  const [userDetails, setUserDetails] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    if (subscriptionDetails?.subscriptionDetail?.subscription) {
      const { user } = subscriptionDetails?.subscriptionDetail?.subscription;
      const { subscription: userSubscription } = user;
      setUserDetails(user);
      setSubscription(userSubscription);
    }
  }, [subscriptionDetails]);

  useEffect(() => {
    dispatch(fetchSubscriptionDetails());
  }, [dispatch]);

  if (!userDetails || !subscription) {
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  const InfoItem = ({ icon, label, value, width = 6 }) => (
    <Grid item xs={12} md={width}>
      <StatBox>
        <Box sx={{ color: "primary.main" }}>{icon}</Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="caption" color="text.secondary">
            {label}
          </Typography>
          <Typography variant="body1" fontWeight={500} color="text.primary">
            {value}
          </Typography>
        </Box>
      </StatBox>
    </Grid>
  );

  return (
    <Container
      id="faq"
      sx={{
        mt: { xs: 12, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Grid container spacing={3}>
        {/* Left Column - Profile Overview */}
        <Grid item xs={12} md={4}>
          <InfoCard>
            <Stack alignItems="center" spacing={3}>
              <Avatar
                src={userDetails.picture}
                alt={userDetails.name}
                sx={{
                  width: 120,
                  height: 120,
                  border: "4px solid",
                  borderColor: "primary.main",
                }}
              />
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  gutterBottom
                  fontWeight={600}
                  color="text.primary"
                >
                  {userDetails.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {userDetails.email}
                </Typography>
                <Chip
                  label={subscription.planId}
                  color="primary"
                  sx={{
                    px: 2,
                    mt: 1,
                    height: 32,
                    fontWeight: 500,
                  }}
                />
              </Box>
              <StyledButton
                onClick={() => navigate("/price")}
                variant="outlined"
                fullWidth
              >
                プランを変更
              </StyledButton>
            </Stack>
          </InfoCard>
        </Grid>

        {/* Right Column - Account Details */}
        <Grid item xs={12} md={8}>
          {/* Account Info Card */}
          <InfoCard sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mb: 3, fontWeight: 600 }}
              color="text.primary"
            >
              アカウント情報
            </Typography>
            <Grid container spacing={2}>
              <InfoItem
                icon={<EmailIcon />}
                label="メールアドレス"
                value={userDetails.email}
              />
              <InfoItem
                icon={<WalletIcon />}
                label="財布"
                value={userDetails.mainWallet}
              />
              <InfoItem
                icon={<CalendarIcon />}
                label="アカウント作成日"
                value={formatDate(userDetails.createdAt)}
              />
              <InfoItem
                icon={<StatusIcon />}
                label="ステータス"
                value={subscription.status}
              />
            </Grid>
          </InfoCard>

          {/* Subscription Stats Card */}
          <InfoCard>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ mb: 3, fontWeight: 600 }}
              color="text.primary"
            >
              利用状況
            </Typography>
            <Grid container spacing={2}>
              <InfoItem
                icon={<UploadIcon />}
                label="当月合計アップロード数"
                value={subscription.montlyPdfUpload}
              />
              <InfoItem
                icon={<DownloadIcon />}
                label="当月合計ダウンロード数"
                value={subscription.montlyPdfDownload}
              />
              <InfoItem
                icon={<CalendarIcon />}
                label="次回支払日付"
                value={formatDate(subscription.expireDate)}
                width={12}
              />
            </Grid>
          </InfoCard>
        </Grid>
      </Grid>
    </Container>
  );
}
