import * as React from "react";
import Box from "@mui/material/Box";
import ChatbotFileUploadModal from "./chat-bot-file-upload";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../loader/simple-backdrop";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../../../features/chatbot/chatbotSlice";
import ConfirmDialog from "../../../dialog/ConfirmDialog";
import DashboardButtons from "./ChatbotSubDetailsTab";

export default function ChatbotFileList({
  chatbotId,
  showChatHistory,
  handleChatHistory,
}) {
  const dispatch = useDispatch();
  const { chatbot } = useSelector((state) => state.chatbot);
  const { enqueueSnackbar } = useSnackbar();
  const [openFileUploadModal, setOpenFileUploadModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pdfDocuments, setPdfDocuments] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState("");

  const handleDeleteFile = async (fileId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete("/user/chatbot/delete-file", {
        data: { fileId, chatbotId }, // Send data in the body
      });
      enqueueSnackbar("ファイルが正常に削除されました。", {
        variant: "success",
      });
      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      setConfirmDialogOpen(false);
      setSelectedFileId("");
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setPdfDocuments(chatbotData.pdfDocuments);
      }
    }
  }, [chatbot, chatbotId]);

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      {openFileUploadModal && (
        <ChatbotFileUploadModal
          chatbotId={chatbotId}
          open={openFileUploadModal}
          handleChangeModal={(state) => setOpenFileUploadModal(state)}
        />
      )}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={() => handleDeleteFile(selectedFileId)}
          title="チャットボットの削除"
          content="このファイルを削除してもよろしいですか? この操作は元に戻せません。"
        />
      )}
      {/* top area */}
      <Box>
        <DashboardButtons
          showChatHistory={showChatHistory}
          handleChatHistory={handleChatHistory}
          chatbotId={chatbotId}
        />
      </Box>
    </React.Fragment>
  );
}
