import React from "react";
import { Tabs, Tab, Box, Typography, Badge } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AllFileList from "./all-file-list";
import ChatbotManage from "./ChatbotManage";
import { styled } from "@mui/material/styles";
import { FiSettings } from "react-icons/fi";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  "& .MuiTabs-indicator": {
    height: 3,
    borderRadius: "3px 3px 0 0",
  },
  "& .MuiTab-root": {
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 56,
  padding: "6px 24px",
  borderRadius: "8px 8px 0 0",
  textTransform: "none",
  fontSize: "0.875rem",
  fontWeight: 500,
  color: theme.palette.text.secondary,
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabPanelWrapper = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: "0 0 16px 16px",
  minHeight: "76vh",
}));

const TabIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
}));

export default function ChatbotSubDetailsTab({
  chatbotId,
  showChatHistory,
  handleChatHistory,
}) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        borderRadius: "16px",
        p: 2,
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="chatbot management tabs"
            variant="fullWidth"
          >
            <StyledTab
              value="1"
              label={
                <TabIcon>
                  <FiSettings style={{ fontSize: "1rem" }} />
                  <Typography sx={{ fontSize: "0.875rem" }}>設定</Typography>
                </TabIcon>
              }
            />
            <StyledTab
              value="2"
              label={
                <TabIcon>
                  <Badge color="primary" variant="dot">
                    <SnippetFolderIcon />
                  </Badge>
                  <Typography sx={{ fontSize: "0.875rem" }}>
                    ファイル
                  </Typography>
                </TabIcon>
              }
            />
          </StyledTabs>
        </Box>

        <TabPanelWrapper value="1">
          <ChatbotManage
            showChatHistory={showChatHistory}
            chatbotId={chatbotId}
            handleChatHistory={handleChatHistory}
          />
        </TabPanelWrapper>

        <TabPanelWrapper value="2">
          <AllFileList chatbotId={chatbotId} />
        </TabPanelWrapper>
      </TabContext>
    </Box>
  );
}
