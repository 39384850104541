import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import ChatbotFileList from "../chatbox/sub/chatbot-file-list";
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  LinearProgress,
  List,
  ListItem,
  Avatar,
  Fade,
  Container,
  useTheme,
  Paper,
  styled,
  Stack,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PersonIcon from "@mui/icons-material/Person";
import axiosInstance from "../../../api/axiosInstance";
import { TbMessageChatbot } from "react-icons/tb";
import { enqueueSnackbar } from "notistack";
import ChatbotMoreVecIcon from "../chatbox/sub/chatbotMoreVecIcon";
import { RiChatHistoryFill } from "react-icons/ri";
import ChatbotInteractionTabs from "./sub/ChatbotInteractionTabs";
import ChatbotPreviewModal from "./preview/ChatbotPreviewModal";

const ChatContainer = styled(Paper)(({ theme }) => ({
  height: "88vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  boxShadow: "none",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
}));

const ScrollWrapper = styled(Box)`
  flex: 1;
  overflow-y: auto;
  padding-right: 8px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const MessageList = styled(List)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  minHeight: "100%", // This ensures the list takes full height
}));

const InputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: "#f5f5f5",
  marginTop: "auto", // Ensures it stays at the bottom
}));

const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isUser",
})(({ theme, isUser }) => ({
  padding: theme.spacing(1.5, 2),
  maxWidth: "70%",
  backgroundColor: isUser
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: isUser
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  boxShadow: "none",
  border: isUser ? "none" : `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(2),
}));

const ChatBoxV2 = ({ chatbot, setSelectedChatbot }) => {
  const theme = useTheme();
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "こんにちは、NCC AI チャットボットです。",
      sender: "ai",
      timestamp: new Date(),
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [chatLimits, setChatLimits] = useState({
    maxNoChatMontly: chatbot?.maxNoChatMontly,
    remainingNoChatMontly: chatbot?.remainingNoChatMontly,
  });
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleSendMessage = async (userMessage) => {
    const newUserMessage = {
      id: messages.length + 1,
      text: userMessage,
      sender: "user",
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, newUserMessage]);
    setIsLoading(true);
    setIsTyping(true);

    if (userMessage.length >= 200) {
      enqueueSnackbar("Message is too long", { variant: "warning" });
      setIsTyping(false);
      setIsLoading(false);
      return;
    }

    try {
      const historyList = messages
        .filter((msg) => msg.sender === "user")
        .slice(-10)
        .map((msg) => msg.text);

      const payload = {
        message: userMessage,
        chatbotId: chatbot._id,
        history: historyList,
      };

      const response = await axiosInstance.post(`/user/chatbot/query`, payload);
      const { maxNoChatMontly, remainingNoChatMontly, text } = response.data;

      const formattedText = text
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/\n\n/g, "</p><p>")
        .replace(/\n/g, "<br />");

      setChatLimits({ maxNoChatMontly, remainingNoChatMontly });
      const botResponse = {
        id: messages.length + 2,
        sender: "ai",
        text: formattedText || response.data.message,
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, botResponse]);
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error ||
          error?.response?.data ||
          "Error occurred",
        { variant: "error" }
      );
    } finally {
      setIsTyping(false);
      setIsLoading(false);
      setInput("");
    }
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {isPreviewOpen && (
            <ChatbotPreviewModal
              open={isPreviewOpen}
              onClose={() => setIsPreviewOpen(false)}
              chatbot={chatbot}
            />
          )}
          <ChatContainer>
            {showChatHistory ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ChatbotInteractionTabs
                  handleChatHistory={(state) => setShowChatHistory(state)}
                  chatbotId={chatbot._id}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  {/* Left side - Chatbot info */}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {chatbot?.logo.imgSrc ? (
                      <Avatar alt="AI Assistant" src={chatbot?.logo.imgSrc} />
                    ) : (
                      <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                        <TbMessageChatbot />
                      </Avatar>
                    )}
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {chatbot.name || "AI Assistant"}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Chats: {chatLimits.remainingNoChatMontly}/
                        {chatLimits.maxNoChatMontly}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Right side - Settings icon */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Stack spacing={2} direction={"row"}>
                      {/* <Tooltip title="Chatbot preview">
                        <IconButton onClick={() => setIsPreviewOpen(true)}>
                          <RiChatHistoryFill />
                        </IconButton>
                      </Tooltip> */}

                      <Tooltip title="チャット履歴を開く">
                        <IconButton onClick={() => setShowChatHistory(true)}>
                          <RiChatHistoryFill />
                        </IconButton>
                      </Tooltip>

                      <ChatbotMoreVecIcon
                        chatBot={chatbot}
                        setSelectedChatbot={setSelectedChatbot}
                      />
                    </Stack>
                  </Box>
                </Box>
                <ScrollWrapper sx={{ flex: 1 }}>
                  {/* Messages */}
                  <MessageList>
                    {messages.map((message) => (
                      <ListItem
                        key={message.id}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems:
                            message.sender === "user"
                              ? "flex-end"
                              : "flex-start",
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            gap: 1,
                            flexDirection:
                              message.sender === "user" ? "row-reverse" : "row",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 32,
                              height: 32,
                              bgcolor:
                                message.sender === "user"
                                  ? "primary.main"
                                  : "grey.200",
                            }}
                          >
                            {message.sender === "user" ? (
                              <PersonIcon />
                            ) : (
                              <TbMessageChatbot style={{ color: "black" }} />
                            )}
                          </Avatar>
                          <MessageBubble isUser={message.sender === "user"}>
                            {message.sender === "ai" ? (
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "0.85rem" }}
                                dangerouslySetInnerHTML={{
                                  __html: message.text,
                                }}
                              />
                            ) : (
                              <Typography
                                sx={{ fontSize: "0.85rem" }}
                                variant="body1"
                              >
                                {message.text}
                              </Typography>
                            )}
                          </MessageBubble>
                        </Box>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ mt: 0.5, mx: 1 }}
                        >
                          {formatTime(message.timestamp)}
                        </Typography>
                      </ListItem>
                    ))}
                    {isTyping && (
                      <Fade in={isTyping}>
                        <ListItem sx={{ p: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              gap: 1,
                            }}
                          >
                            <Avatar
                              sx={{
                                width: 32,
                                height: 32,
                                bgcolor: "grey.200",
                              }}
                            >
                              <SmartToyIcon />
                            </Avatar>
                            <Paper
                              sx={{
                                p: 2,
                                backgroundColor: theme.palette.background.paper,
                                border: `1px solid ${theme.palette.divider}`,
                                borderRadius: 2,
                                minWidth: 100,
                              }}
                            >
                              <LinearProgress sx={{ borderRadius: 1 }} />
                            </Paper>
                          </Box>
                        </ListItem>
                      </Fade>
                    )}
                    <div ref={messagesEndRef} />
                  </MessageList>
                </ScrollWrapper>
                {/* Input */}
                <InputContainer
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1,
                    background: "#f5f5f5",
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (!isLoading) handleSendMessage(e.target.message.value);
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <TextField
                        fullWidth
                        name="message"
                        placeholder="Type a message..."
                        size="small"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        disabled={isLoading}
                        inputProps={{ maxLength: 500 }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 3,
                            backgroundColor: "white",
                          },
                        }}
                      />
                      <IconButton
                        color="primary"
                        disabled={isLoading || !input}
                        type="submit"
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          color: "white",
                          "&:hover": {
                            bgcolor: theme.palette.primary.dark,
                          },
                          "&.Mui-disabled": {
                            bgcolor: theme.palette.action.disabledBackground,
                            color: theme.palette.action.disabled,
                          },
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </Box>
                  </form>
                </InputContainer>
              </Box>
            )}
          </ChatContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <ChatbotFileList
            showChatHistory={showChatHistory}
            chatbotId={chatbot._id}
            handleChatHistory={(state) => setShowChatHistory(state)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChatBoxV2;
