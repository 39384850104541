import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  ListItemIcon,
  Typography,
  Button,
} from "@mui/material";
import {
  Person as PersonIcon,
  Logout as LogoutIcon,
  CardMembership as SubscriptionIcon,
  MenuBook as GuideIcon,
} from "@mui/icons-material";

const HeaderMenu = ({ userDetails, onLogout }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {/* Guide Button */}
      <Button
        onClick={() => navigate("/guide")}
        startIcon={<GuideIcon />}
        sx={{
          color: "black",
          "&:hover": { backgroundColor: "rgba(0,0,0,0.04)" },
        }}
      >
        ガイド
      </Button>

      {/* Profile Avatar & Menu */}
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ padding: 0 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          src={userDetails?.picture}
          alt={userDetails?.name}
          sx={{
            width: 40,
            height: 40,
            border: "2px solid",
            borderColor: "primary.main",
          }}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            mt: 1.5,
            borderRadius: 2,
            minWidth: 220,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* User Info Section */}
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="subtitle1" fontWeight={600}>
            {userDetails?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userDetails?.email}
          </Typography>
        </Box>

        <Divider />

        {/* Menu Items */}
        <MenuItem onClick={() => navigate("/profile")}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          プロフィール
        </MenuItem>

        <MenuItem onClick={() => navigate("/price")}>
          <ListItemIcon>
            <SubscriptionIcon fontSize="small" />
          </ListItemIcon>
          サブスクリプション
        </MenuItem>

        <MenuItem onClick={() => navigate("/guide")}>
          <ListItemIcon>
            <GuideIcon fontSize="small" />
          </ListItemIcon>
          ガイド
        </MenuItem>

        <Divider />

        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography color="error.main">ログアウト</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default HeaderMenu;
