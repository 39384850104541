import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import {
  Send as SendIcon,
  Close as CloseIcon,
  Chat as ChatIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TbMessageChatbot } from "react-icons/tb";

// Update the styled components
const PreviewContainer = styled(Paper)(({ theme, deviceType }) => ({
  width: "100%",
  height: deviceType === "mobile" ? "100%" : "75vh",
  backgroundColor: "#f8f9fa",
  overflow: "hidden",
  position: "relative",
  border: "none",
  display: "flex",
  flexDirection: "column",
}));

const ChatbotWidget = styled(motion.div)(({ theme, deviceType }) => ({
  position: "absolute",
  ...(deviceType === "mobile"
    ? {
        top: "12px",
        left: "12px",
        right: "12px",
        bottom: "12px",
        width: "auto",
        height: "auto",
        borderRadius: theme.spacing(2),
        boxShadow: "0 4px 24px rgba(0, 0, 0, 0.1)",
      }
    : {
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        width: "360px",
        height: "520px",
        borderRadius: theme.spacing(2),
        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
      }),
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  zIndex: 100,
}));

const ChatHeader = styled(Box)(({ theme, deviceType }) => ({
  padding: deviceType === "mobile" ? theme.spacing(1.5) : theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: deviceType === "mobile" ? "60px" : "72px",
}));

const ChatMessages = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  backgroundColor: "#fff",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ddd",
    borderRadius: "2px",
  },
}));

const Message = styled(motion.div)(({ theme, isBot }) => ({
  maxWidth: "85%",
  alignSelf: isBot ? "flex-start" : "flex-end",
  backgroundColor: isBot ? "#f5f5f5" : theme.palette.primary.main,
  color: isBot
    ? theme.palette.text.primary
    : theme.palette.primary.contrastText,
  padding: theme.spacing(1.25, 1.75),
  borderRadius: isBot ? "16px 16px 16px 4px" : "16px 16px 4px 16px",
  position: "relative",
  fontSize: "0.9rem",
  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.05)",
}));

const InputWrapper = styled(Box)(({ theme, deviceType }) => ({
  padding: deviceType === "mobile" ? theme.spacing(1.5) : theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const WebsiteHeader = styled(Box)(({ theme }) => ({
  height: "60px",
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 3),
  flexShrink: 0,
}));

const WebsiteContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  flexGrow: 1,
  overflowY: "auto",
  position: "relative",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#ddd",
    borderRadius: "4px",
  },
}));

// Demo conversations with more engaging content
const demoMessages = [
  { text: "こんにちは！サービスについてご質問がありますか？", isBot: true },
  { text: "はい、料金プランについて詳しく知りたいです", isBot: false },
  {
    text: "承知いたしました。現在、以下の3つのプランをご用意しています：\n\n✨ スタータープラン: ¥5,000/月\n💫 プロプラン: ¥12,000/月\n⭐ エンタープライズプラン: カスタム価格\n\nどのプランにご興味がありますか？",
    isBot: true,
  },
  { text: "スタータープランの機能を教えてください", isBot: false },
  {
    text: "スタータープランには以下の機能が含まれます：\n\n✓ 月間3,000メッセージ\n✓ 基本的なAIチャット機能\n✓ カスタマイズ可能なボットの外観\n✓ 基本的な分析レポート\n✓ メールサポート\n\nまた、14日間の無料トライアルもご利用いただけます！",
    isBot: true,
  },
  { text: "はい、料金プランについて詳しく知りたいです", isBot: false },
  {
    text: "承知いたしました。現在、以下の3つのプランをご用意しています：\n\n✨ スタータープラン: ¥5,000/月\n💫 プロプラン: ¥12,000/月\n⭐ エンタープライズプラン: カスタム価格\n\nどのプランにご興味がありますか？",
    isBot: true,
  },
  { text: "スタータープランの機能を教えてください", isBot: false },
  {
    text: "スタータープランには以下の機能が含まれます：\n\n✓ 月間3,000メッセージ\n✓ 基本的なAIチャット機能\n✓ カスタマイズ可能なボットの外観\n✓ 基本的な分析レポート\n✓ メールサポート\n\nまた、14日間の無料トライアルもご利用いただけます！",
    isBot: true,
  },
  { text: "こんにちは！サービスについてご質問がありますか？", isBot: true },
  { text: "はい、料金プランについて詳しく知りたいです", isBot: false },
  {
    text: "承知いたしました。現在、以下の3つのプランをご用意しています：\n\n✨ スタータープラン: ¥5,000/月\n💫 プロプラン: ¥12,000/月\n⭐ エンタープライズプラン: カスタム価格\n\nどのプランにご興味がありますか？",
    isBot: true,
  },
  { text: "スタータープランの機能を教えてください", isBot: false },
  {
    text: "スタータープランには以下の機能が含まれます：\n\n✓ 月間3,000メッセージ\n✓ 基本的なAIチャット機能\n✓ カスタマイズ可能なボットの外観\n✓ 基本的な分析レポート\n✓ メールサポート\n\nまた、14日間の無料トライアルもご利用いただけます！",
    isBot: true,
  },
  { text: "はい、料金プランについて詳しく知りたいです", isBot: false },
  {
    text: "承知いたしました。現在、以下の3つのプランをご用意しています：\n\n✨ スタータープラン: ¥5,000/月\n💫 プロプラン: ¥12,000/月\n⭐ エンタープライズプラン: カスタム価格\n\nどのプランにご興味がありますか？",
    isBot: true,
  },
  { text: "スタータープランの機能を教えてください", isBot: false },
  {
    text: "スタータープランには以下の機能が含まれます：\n\n✓ 月間3,000メッセージ\n✓ 基本的なAIチャット機能\n✓ カスタマイズ可能なボットの外観\n✓ 基本的な分析レポート\n✓ メールサポート\n\nまた、14日間の無料トライアルもご利用いただけます！",
    isBot: true,
  },
];

const ChatbotPreview = ({ chatbot, deviceType = "desktop" }) => {
  // Set initial open state to false for both mobile and desktop
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isTypingDemo, setIsTypingDemo] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const addMessage = useCallback((message) => {
    setMessages((prev) => [...prev, message]);
  }, []);

  const simulateTyping = useCallback(async (text, callback) => {
    setIsTypingDemo(true);
    let currentText = "";
    const delay = 50;

    for (let i = 0; i < text.length; i++) {
      currentText += text[i];
      setInputValue(currentText);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    setIsTypingDemo(false);
    callback();
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    const showNextMessage = async () => {
      if (currentMessageIndex >= demoMessages.length) {
        setCurrentMessageIndex(0);
        setMessages([]);
        return;
      }

      const message = demoMessages[currentMessageIndex];

      if (!message.isBot) {
        await simulateTyping(message.text, () => {
          setInputValue("");
          addMessage(message);
          setCurrentMessageIndex((prev) => prev + 1);
        });
      } else {
        setIsTyping(true);
        setTimeout(() => {
          setIsTyping(false);
          addMessage(message);
          setCurrentMessageIndex((prev) => prev + 1);
        }, 1500);
      }
    };

    const timer = setTimeout(
      showNextMessage,
      currentMessageIndex === 0
        ? 500
        : !demoMessages[currentMessageIndex]?.isBot
        ? 1000
        : 2000
    );

    return () => clearTimeout(timer);
  }, [isOpen, currentMessageIndex, addMessage, simulateTyping]);

  const resetChat = () => {
    setMessages([]);
    setCurrentMessageIndex(0);
    setIsTyping(false);
    setInputValue("");
  };

  const toggleChat = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      resetChat();
    }
  };

  const messageVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.9 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  return (
    <PreviewContainer elevation={0} deviceType={deviceType}>
      {deviceType === "desktop" ? (
        <>
          <WebsiteHeader>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                maxWidth: 1200,
                margin: "0 auto",
                px: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Avatar
                  sx={{
                    width: 36,
                    height: 36,
                    bgcolor: "primary.main",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TbMessageChatbot size={24} />
                </Avatar>
                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{ fontWeight: 600 }}
                >
                  {chatbot?.name || "AI Chat Assistant"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  ホーム
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  機能
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  料金
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    cursor: "pointer",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  お問い合わせ
                </Typography>
              </Box>
            </Box>
          </WebsiteHeader>

          <WebsiteContent>
            <Box
              sx={{
                maxWidth: 1200,
                margin: "0 auto",
                px: 3,
                py: 6,
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {/* Hero Section */}
              <Box sx={{ display: "flex", alignItems: "center", gap: 6 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontWeight: 700,
                      mb: 2,
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    AIチャットで顧客対応を
                    <br />
                    スマートに効率化
                  </Typography>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{ mb: 4, fontWeight: 400 }}
                  >
                    24時間365日、お客様からの問い合わせに即座に対応。
                    人工知能が最適な回答を提供し、顧客満足度を向上させます。
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: -20,
                      right: -20,
                      width: "100%",
                      height: "100%",
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      opacity: 0.1,
                      borderRadius: 4,
                      zIndex: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      borderRadius: 4,
                      p: 4,
                      boxShadow: "0 4px 24px rgba(0,0,0,0.06)",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 2, fontWeight: 600 }}
                    >
                      デモチャットを体験
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      右下のチャットボタンをクリックして、AIチャットボットの動作をご確認ください。
                      実際の導入イメージを体験いただけます。
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </WebsiteContent>
        </>
      ) : (
        <Box sx={{ p: 2, height: "100%" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mt: 8, textAlign: "center" }}
          >
            チャットボットデモ
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", color: "text.secondary" }}
          >
            下のボタンをクリックしてチャットを開始
          </Typography>
        </Box>
      )}

      <AnimatePresence>
        {!isOpen && (
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            style={{
              position: "absolute",
              bottom: deviceType === "mobile" ? 32 : 24,
              right: 24,
              zIndex: 10,
            }}
          >
            <IconButton
              onClick={toggleChat}
              id="tour-chatwidget" // Ensure this ID is present
              data-tour="chat-widget" // Add data attribute for additional targeting
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                width: deviceType === "mobile" ? 64 : 56,
                height: deviceType === "mobile" ? 64 : 56,
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: "primary.dark",
                  transform: "scale(1.1)",
                },
              }}
            >
              <ChatIcon sx={{ fontSize: deviceType === "mobile" ? 32 : 24 }} />
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isOpen && (
          <ChatbotWidget
            deviceType={deviceType}
            initial={
              deviceType === "mobile"
                ? { opacity: 0, scale: 0.9 }
                : { opacity: 0, y: 100, scale: 0.3 }
            }
            animate={
              deviceType === "mobile"
                ? { opacity: 1, scale: 1 }
                : { opacity: 1, y: 0, scale: 1 }
            }
            exit={
              deviceType === "mobile"
                ? { opacity: 0, scale: 0.9 }
                : { opacity: 0, y: 100, scale: 0.3 }
            }
            transition={{ type: "spring", damping: 25, stiffness: 300 }}
          >
            <ChatHeader deviceType={deviceType}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar
                  sx={{
                    bgcolor: "primary.dark",
                    width: deviceType === "mobile" ? 32 : 40,
                    height: deviceType === "mobile" ? 32 : 40,
                  }}
                >
                  <TbMessageChatbot size={deviceType === "mobile" ? 20 : 24} />
                </Avatar>
                <Box>
                  <Typography
                    variant={deviceType === "mobile" ? "body1" : "subtitle1"}
                    fontWeight={600}
                    sx={{
                      fontSize: deviceType === "mobile" ? "0.9rem" : "1rem",
                      lineHeight: 1.2,
                    }}
                  >
                    {chatbot?.name || "AI Assistant"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      opacity: 0.8,
                      fontSize: deviceType === "mobile" ? "0.75rem" : "0.8rem",
                      display: "block",
                      marginTop: "2px",
                    }}
                  >
                    オンライン
                  </Typography>
                </Box>
              </Box>
              <IconButton
                size={deviceType === "mobile" ? "small" : "medium"}
                onClick={toggleChat}
                sx={{
                  color: "inherit",
                  padding: deviceType === "mobile" ? 0.5 : 1,
                  "&:hover": {
                    transform: "rotate(90deg)",
                    transition: "transform 0.3s",
                  },
                }}
              >
                <CloseIcon
                  sx={{ fontSize: deviceType === "mobile" ? 20 : 24 }}
                />
              </IconButton>
            </ChatHeader>

            <ChatMessages>
              <AnimatePresence mode="popLayout">
                {messages.map((message, index) => (
                  <Message
                    key={index}
                    isBot={message.isBot}
                    variants={messageVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3 }}
                  >
                    <Typography
                      variant="body2"
                      whiteSpace="pre-line"
                      sx={{
                        fontSize:
                          deviceType === "mobile" ? "0.85rem" : "0.9rem",
                        lineHeight: 1.5,
                      }}
                    >
                      {message.text}
                    </Typography>
                  </Message>
                ))}
                {isTyping && (
                  <Message
                    isBot
                    variants={messageVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3 }}
                  >
                    <Box sx={{ display: "flex", gap: 0.5, py: 0.5 }}>
                      {[0, 1, 2].map((i) => (
                        <motion.div
                          key={i}
                          style={{
                            width: deviceType === "mobile" ? 6 : 8,
                            height: deviceType === "mobile" ? 6 : 8,
                            borderRadius: "50%",
                            backgroundColor: "currentColor",
                          }}
                          animate={{ y: [0, -5, 0] }}
                          transition={{
                            duration: 0.6,
                            repeat: Infinity,
                            delay: i * 0.2,
                          }}
                        />
                      ))}
                    </Box>
                  </Message>
                )}
              </AnimatePresence>
              <div ref={messagesEndRef} />
            </ChatMessages>

            <InputWrapper deviceType={deviceType}>
              <TextField
                fullWidth
                value={inputValue}
                placeholder="メッセージを入力..."
                size="small"
                disabled
                InputProps={{
                  sx: {
                    fontSize: deviceType === "mobile" ? "0.85rem" : "0.9rem",
                    "& .MuiInputBase-input": {
                      padding:
                        deviceType === "mobile" ? "8px 14px" : "10px 14px",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        disabled
                        sx={{
                          color: inputValue
                            ? "primary.main"
                            : "action.disabled",
                          padding: deviceType === "mobile" ? 0.5 : 1,
                          transition: "transform 0.2s",
                          "&:not(:disabled):hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                      >
                        <SendIcon
                          sx={{ fontSize: deviceType === "mobile" ? 18 : 24 }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "background.paper",
                    borderRadius: 2,
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                  },
                }}
              />
            </InputWrapper>
          </ChatbotWidget>
        )}
      </AnimatePresence>
    </PreviewContainer>
  );
};

export default ChatbotPreview;
